<template>
	<a-card title="" :bordered="false">
		<a-row class="top-total">
			<a-col v-for="(item, k) in overviewData" :key="k" :md="6" :xs="4" :span="2">
				<div class="top-total__item" :class="item.class">
                    <b>{{ item.value }}</b>
					<p v-if="k != 'prestige'">{{ item.name }}</p>
					<p v-else>
						<a-tooltip placement="left">
							<div slot="title" class="top-total__tooltip">
								<p>每在截止时间前3天提交稿件，加1分</p>
								<p>截止日期3天内提交不加分</p>
								<p>超过截止日期，未提交，扣5分</p>
							</div>
							<a-tag v-if="item.value >= 100" color="#27B148">信誉优秀&emsp;<a-icon type="question-circle" /></a-tag>
							<a-tag v-else-if="item.value >= 80" color="#FC9400">信誉良好&emsp;<a-icon type="question-circle" /></a-tag>
							<a-tag v-else-if="item.value >= 1" color="#FF2525">信誉差&emsp;<a-icon type="question-circle" /></a-tag>
							<a-tag v-else>信誉分<a-icon type="question-circle" /></a-tag>
						</a-tooltip>
					</p>
				</div>
			</a-col>
		</a-row>
		<template v-if="noticeData.length">
			<div class="sub-title">消息通知</div>
			<a-collapse class="notice-collapse" expand-icon-position="right" :destroy-inactive-panel="true" @change="collapseChange">
                <a-collapse-panel class="notice-panel" v-for="(item, index) in noticeData" :key="index">
                    <div class="notice-panel__header" slot="header">
                        <span class="notice-panel__status" :class="{'notice-panel__status--unread': item.is_read == 0}"></span>
                        <span class="notice-panel__title">{{item.title}}</span>
                        <span class="notice-panel__time">{{formatTime(item.create_time)}}</span>
                    </div>
                    <div class="notice-panel__content" v-html="item.content"></div>
                </a-collapse-panel>
            </a-collapse>
		</template>
		<template v-if="taskData.length">
			<div class="sub-title">任务中心</div>
			<a-row class="task-list" :gutter="24">
				<a-col v-for="(item, index) in taskData" :key="index" :md="12" :xs="20" :span="2">
					<div class="task-list__item">
						<div class="task-list__title">{{ item.title }}</div>
						<div class="task-list__desc">{{ item.desc }}</div>
						<div class="task-list__progress">
							<p>￥{{ item.reward }}</p>
							<template v-if="item.status == 1">
								<a-button type="primary" class="doing" v-if="item.iden == 'formcliques'" @click="copySpread">复制链接</a-button>
								<template v-else>
									<a-popover overlayClassName="calendar-popover" v-if="item.iden == 'perserve'">
										<div class="calendar-popover__wrap" slot="content">
											<div class="calendar-popover__load" v-if="perserveLoad">
												<a-icon type="loading" spin style="font-size: 40px;" />
											</div>
											<div class="calendar-popover__none" v-else-if="perserveData.length <= 0">
												<div>
													<a-icon type="alert" style="font-size: 30px;" />
													<p>暂无数据</p>
												</div>
											</div>
											<template v-else>
												<div class="calendar-popover__listtop">本轮开始打卡时间：{{perserveStart}}</div>
												<ul class="calendar-popover__list">
													<li class="th">一</li>
													<li class="th">二</li>
													<li class="th">三</li>
													<li class="th">四</li>
													<li class="th">五</li>
													<li class="th">六</li>
													<li class="th">日</li>
													<li v-for="(item,k) in perserveData" :key="k">
														<p>{{item.date}}</p>
														<a-icon type="check-circle" style="color: #34BC6E" v-if="item.status === true" />
														<a-icon type="close-circle" style="color: #A3A8AE" v-if="item.status === false" />
													</li>
												</ul>
											</template>
										</div>
										<a-button type="primary" class="doing" @click="taskCancel(item, index)">
											<span class="doing--default">进行中{{item.process}}% </span>
											<span class="doing--cancel">取消任务</span>
										</a-button>
									</a-popover>
									<a-button type="primary" class="doing" v-else-if="item.iden == 'outstanding'" @click="taskCancel(item, index)">
										<span class="doing--default">进行中{{item.process}}% </span>
										<span class="doing--cancel">取消任务</span>
									</a-button>
									<a-button type="primary" class="doing" v-else>进行中{{item.process}}%</a-button>
								</template>
							</template>
							<template v-if="item.status == 2">
								<a-button type="primary" class="start" v-if="item.is_repeat == 1" @click="taskTake(item, index)">再次领取</a-button>
								<a-button type="primary" class="success" v-else>已完成</a-button>
							</template>
							<a-button type="primary" class="start" v-if="item.status == 0" @click="taskTake(item, index)">领取任务</a-button>
							<a-button type="primary" class="start" loading v-if="item.status == 99"></a-button>
						</div>
					</div>
				</a-col>
			</a-row>
		</template>
		<first-login />
	</a-card>
</template>

<script>
import { formatTime } from '@/common/date';
import firstLogin from "@/components/firstLogin";
export default {
	components: {
		firstLogin,
	},
	data() {
		return {
			overviewData: {
				todo: {
					name: "待完成稿件",
					value: '--',
				},
				total: {
					name: "累计创作",
					value: '--',
				},
				income: {
					name: "待结算收入",
					value: '--',
				},
				prestige: {
					name: "信誉分",
					value: '--',
				}
			},
			noticeData: [],
			taskData: [],
			// 持之以恒数据
			perserveStart: '',
			perserveData: [],
			perserveLoad: false,
		};
	},
	created() {
		// 测试数据
		this.loading = true;
		// 获取置顶数据
		this.$api.indexTop().then((res) => {
			if(res.status) {
				let resData = res.data;
				this.overviewData.todo.value = resData.tobe_complete;
				this.overviewData.total.value = resData.run_up;
				this.overviewData.income.value = '￥'+ resData.income;
				this.overviewData.prestige.value = resData.credit;
			}
		})
		// 获取消息通知数据
		this.$store.dispatch('notice/getList').then(res => {
			this.noticeData = res.slice(0, 3);
		})
		// 获取任务数据
		this.$api.taskCenter().then((res) => {
			if(res.status) {
				let resTask = res.data;
				resTask['fledgling'] && this.taskData.push(resTask['fledgling']);
				resTask['greenhand'] && this.taskData.push(resTask['greenhand']);
				resTask['perserve'] && this.taskData.push(resTask['perserve']);
				resTask['outstanding'] && this.taskData.push(resTask['outstanding']);
				resTask['formcliques'] && this.taskData.push(resTask['formcliques']);
				// 获取持之以恒任务数据
				if(resTask['perserve'] && resTask['perserve']['status'] == 1) {
					this.getPerserveData();
				}
			}
		})
	},
	methods: {
		// 持之以恒数据
		getPerserveData() {
			this.perserveLoad = true;
			this.$api.perserveCompletion().then(res => {
				if(!res.status) return;
				if(JSON.stringify(res.data) === '[]') {
					this.perserveData = [];
					this.perserveLoad = false;
					return;
				}

				let resList = res.data.list,
					objIndex = 0,
					listArr = [];
				
				let startTimer = parseInt(Object.keys(resList)[0]);
				let endTimer = parseInt(Object.keys(resList)[Object.keys(resList).length -1]);
				// 星期补全
				for(let d = 1, max = new Date(startTimer * 1000).getDay(); d < max; d++) {
					listArr.push({
						date: '-',
						status: null
					})
				}
				// 遍历日期
				for(let i = startTimer; i <= endTimer; i = i + 86400) {
					listArr.push({
						date: formatTime(i * 1000, 'm.d'),
						status: (resList[i] && resList[i].complete) || false
					})
				}
				this.perserveStart = formatTime(res.data.start_time * 1000, 'Y-M-D H:I')
				this.perserveData = listArr;
				this.perserveLoad = false;
			})
		},
		// 格式化时间
        formatTime(timestamp, format) {
            return formatTime(timestamp * 1000, format);
        },
		// 标记已读
        collapseChange(key) {
            let keyArr = key;
            const noticeData = this.noticeData;
            for(let i = 0, max = keyArr.length; i < max; i++) {
                if(noticeData[keyArr[i]].is_read == 0) {
                    noticeData[keyArr[i]].is_read = 1;
                    this.$store.dispatch('notice/reportRead', {
                        id: noticeData[keyArr[i]].id,
                        page: this.pageCurrent
                    });
                }
            }
        },
		// 领取任务
		taskTake(item, index) {
			let iden = item.iden;
			let oldStatus = item.status;
			// 进入加载状态
			this.taskData[index].status = 99;
			this.$api.taskTake({iden}).then((res) => {
				// res = {status: 1};
				if(res.status) {
					this.taskData[index].status = 1;
					this.taskData[index].user_task_id = res.data;
					this.$message.success({ content: '领取成功', key: 'message' });
					// 获取持之以恒数据
					if(iden == 'perserve') {
						this.getPerserveData();
					}
				}else{
					this.taskData[index].status = oldStatus;
					this.$message.error({ content: res.msg, key: 'message' });
				}
			})
		},
		// 取消任务
		taskCancel(item, index) {
			let id = item.user_task_id; // 任务ID
			let taskName = item.title;
			let oldStatus = item.status;
			this.modalErr = this.$modal.info({
				title: '确定取消“'+ taskName +'”任务?',
				okText: '确定',
				closable: true,
				onOk: () =>{
					// 进入加载状态
					this.taskData[index].status = 99;
					this.$api.taskDelete({id}).then((res) => {
						// res = {status: 1};
						if(res.status) {
							this.taskData[index].status = 0;
							this.$message.success({ content: '任务取消成功', key: 'message' });
						}else{
							this.taskData[index].status = oldStatus;
							this.$message.error({ content: res.msg, key: 'message' });
						}
					})
				},
			})
		},
		// 复制推广链接
		copySpread() {
			const _this = this;
			let userInfo = this.$store.state.user.user_info;
			let spread = location.origin +'/?promoter='+ userInfo.id;
			this.$copyText(spread).then(function (e) {
                _this.$message.success({
                    content: '注册链接，复制成功'
                });
            }, function (e) {
                _this.$message.error({
                    content: '复制失败'
                });
            })
		},
	}
};
</script>

<style lang="less" scoped>
// 顶部合计
.top-total{
	border: 1px solid #e6e6e6;
	border-radius: 4px;
	&__item{
		text-align: center;
		padding: 15px 0;
		p {
			margin: 5px 0 0;
		}
		b {
			font-size: 2em;
		}
		/deep/ .ant-tag{
			margin-right: 0;
		}
	}
	&__tooltip{
		width: 320px;
		p{
			margin-bottom: 5px;
		}
	}
	
}
// 小标题
.sub-title{
	margin-top: 20px;
	font-size: 18px;
	padding: 10px 4px;
}
// 消息通知
.notice-collapse{
    background-color: transparent;
	border: 1px solid #e6e6e6;
	border-bottom: 0;
    /deep/ .ant-collapse-item{
        border-bottom: 1px solid #e6e6e6;
    }
}
.notice-panel{
    &__header{
        padding: 0 240px 0 20px;
        position: relative;
    }
    &__status{
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #d8d8d8;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        &--unread{
            background-color: #dc4e26;
        }
    }
    &__title{
        position: relative;
    }
    &__time{
        position: absolute;
        color: #999;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &__content{
        /deep/ p{
            margin-bottom: 10px;
        }
    }
}

// 任务中心
.task-list{
	&__item{
		border: 1px solid #e6e6e6;
		border-radius: 4px;
		padding: 15px 120px 15px 10px;
		margin-bottom: 20px;
		position: relative;
	}
	&__title{
		font-size: 20px;
	}
	&__desc{
		color: #999;
		line-height: 20px;
		height: 40px;
		overflow: hidden;
	}
	&__progress{
		position: absolute;
		right: 15px;
		width: 90px;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
		p{
			font-size: 20px;
			margin-bottom: 0;
			color: #ff5000;
		}
		/deep/ .ant-btn{
			display: block;
			width: 100%;
		}
		.success{
			background-color: #A3A8AE;
			border-color: #A3A8AE;
		}
		.doing{
			background-color: #ff2525;
			border-color: #ff2525;
			.doing--cancel{
				display: none;
			}
			&:hover .doing--default{
				display: none;
			}
			&:hover .doing--cancel{
				display: block;
			}
		}
		.start{
			background-color: #f7c544;
			border-color: #f7c544;
		}
	}
}
// 持之以恒任务进度日历
.calendar-popover{
	/deep/ .ant-popover-inner-content {
		padding: 0;
	}
	&__wrap{
		width: 360px;
	}
	&__tip{
		color: #999;
	}
	&__load{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 350px;
		height: 200px;
	}
	&__none{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
		width: 350px;
		height: 200px;
	}
	&__listtop{
		padding: 0 0 10px;
	}
	&__list{
		padding: 0;
		margin-bottom: 0;
		overflow: hidden;
		li{
			float: left;
			width: 50px;
			text-align: center;
			padding: 6px 0;
			p{
				margin-bottom: 0;
			}
		}
		.th{
			font-weight: 700;
			padding: 0;
		}
	}
	table {
		position: relative;
		border: 1px solid #dfdfdf;
	}
}
</style>
