<template>
    <a-modal :title="null" :footer="null" :closable="false" :visible="isFirst && !isEnd">
        <div class="guide-wrap">
            <div class="page-1" v-show="step == 1">
                <p>hi，实习生，你好！</p>
                <p>欢迎加入天象创作平台。</p>
                <p>我们的共同使命，是：<b>让世界爱上中华文化</b></p>
                <p>请花3分钟的时间，认真看完前期的指引。</p>
            </div>
            <div class="page-2" v-show="step == 2">
                <h4 class="guide-wrap__title">平台介绍</h4>
                <p>本平台，为学生以及传统文化爱好者而生</p>
                <p>让大家创作的同时，能够获得一些收入</p>
                <p>会员分为3个等级：</p>
                <p>1、实习生</p>
                <p>2、普通作者</p>
                <p>3、优秀作者</p>
            </div>
            <div class="page-3" v-show="step == 3">
                <h4 class="guide-wrap__title">会员等级</h4>
                <p>会员等级非常简单。</p>
                <p>实习生，只要完成实习任务（完成<b>两篇</b>试稿）</p>
                <p>即可升级为普通作者。</p>
                <p>完成实习任务可得<b>15元</b>收入。</p>
            </div>
            <div class="page-4" v-show="step == 4">
                <h4 class="guide-wrap__title">会员等级奖励</h4>
                <p>成为普通作者，即可接收文章任务。</p>
                <p>每篇文章可得到<b>3元以上</b>的收入。</p>
                <p>持续创作，就有机会晋升优秀作者。</p>
                <p>成为优秀作者，获得额外奖励<b>15%</b></p>
            </div>
            <div class="page-5" v-show="step == 5">
                <h4 class="guide-wrap__title">立即开始</h4>
                <p>选择你有兴趣的文章类别（<b>最多三个</b>）</p>
                <div class="category-loading" v-if="categoryLoading">
                    <a-spin size="large" class="spin"  />
                </div> 
                <div class="category-list" v-if="categoryList.length">
                    <div class="category-list__item" :class="{'category-list__item--active': categoryPrimary(item.id)}" v-for="item in categoryList" :key="item.id" @click="categoryChange(item.id)">{{item.title}}<a-icon class="category-list__icon" type="check" v-if="categoryPrimary(item.id)" /></div>
                </div>
            </div>
            <div class="page-6" v-show="step == 6">
                <h4 class="guide-wrap__title">领取实习任务</h4>
                <p style="margin-bottom: 5px">请选择<b>2个</b>标题，开始你的试稿之旅</p>
                <p><b>（完成试稿奖励10元）</b></p>
                <div class="mark-loading" v-if="markLoading">
                    <a-spin size="large" class="spin"  />
                </div> 
                <div class="mark-list" v-if="markList.length">
                    <a-button :type="markPrimary(item.id)" v-for="item in filterMarkList" :key="item.id" @click="markChange(item)">{{item.title}}</a-button>
                </div>
            </div>
            <div class="guide-wrap__bottom">
                <a-button v-show="step == 6" size="large" @click="titleChange"><a-icon type="reload" />换一批标题</a-button>
                <a-button type="primary" size="large" :disabled="nextDisabled" :loading="nextLoading" @click="nextStep">
                    <template v-if="step == 6">开始试稿({{markSelectIds.length}})</template>
                    <template v-else>下一步<a-icon type="right" /></template>
                </a-button>
            </div>
        </div>
    </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
import localStore from 'storejs';
export default {
    data() {
        return {
            isEnd: false,
            step: 1,
            // 类别
            categoryLoading: true,
            categoryList: [],
            categorySelect: [],
            // 文章
            markLoading: true,
            markList: [],
            markSelectIds: [],
            markSelectArr: [],
            markPage: 1,
            nextLoading: false,
            filterMarkList: []
        };
    },
    computed: {
        ...mapGetters({
            isFirst: "user/isFirst",
        }),
        // 下一步是否可点击
        nextDisabled() {
            if(this.step < 5) {
                return false;
            }else if(this.step == 5) {
                return this.categorySelect.length <= 0;
            }else if(this.step == 6) {
                return this.markSelectIds.length != 2;
            }
        },
    },
    created() {
        // 获取类目
        this.$api.manuscriptsCatidGetList().then(res => {
            if(res.status) {
                this.categoryLoading = false;
                this.categoryList = res.data;
            }
        })
        // 判断是否已经首次登陆,一天内
        // let firstLoginTime = localStore('first_login_time') || '';
        // if(parseInt((new Date().getTime()/1000) - firstLoginTime) < 60*60*24) {
        //     this.isEnd = true;
        // }
    },
    methods: {
        // 下一步
        nextStep() {
            if(this.step == 5) {
                // 选择类目
                this.getCenterList();
            }
            // 开始试稿
            if(this.step == 6) {
                // 接稿
                const ids = this.markSelectIds.join(',');
                this.nextLoading = true;
                this.$api.manuscriptsJiegao({ ids }).then(res => {
                    if(res.status) {
                        this.$message.success({ content: '接稿成功', key: 'messageKey'});
                    }else{
                        this.$message.error({ content: res.msg, key: 'messageKey'});
                    }
                }).finally(() => {
                    this.isEnd = true;
                    localStore.set('first_login_time', parseInt(new Date().getTime()/1000))
                    this.$router.push({name: 'writeArticle'});
                    this.nextLoading = false;
                })
                return;
            }
            this.step += 1;
        },
        // 上报类别选择，获取接稿中心文章
        async getCenterList() {
            await this.$api.userManuscriptsCatidUpdate({catids: this.categorySelect});
            this.$api.manuscriptsCenter({
                limit: 200
            }).then(res => {
                // res = {status: 1}
                if(res.status) {
                    this.markLoading = false;
                    this.markList = res.data.list;
                    this.updateMarkList();
                }
            });
        },
        // 类别选中高亮
        categoryPrimary(id) {
            const index = this.categorySelect.indexOf(id)
            return index >= 0;
        },
        // 类别选择切换
        categoryChange(id) {
            const index = this.categorySelect.indexOf(id);
            if(index >= 0) {
                this.categorySelect.splice(index, 1);
            }else if(this.categorySelect.length < 3){
                // 最大只能3项
                this.categorySelect.push(id);
            }
        },
        // 选择稿件高亮
        markPrimary(id) {
            const index = this.markSelectIds.indexOf(id)
            return index >=0 ? 'primary' : '';
        },
        // 类别选择切换
        markChange(item) {
            const index = this.markSelectIds.indexOf(item.id);
            if(index >= 0) {
                this.markSelectIds.splice(index, 1);
                this.markSelectArr.splice(index, 1);
            }else if(this.markSelectIds.length < 2){
                // 最大只能3项
                this.markSelectIds.push(item.id);
                this.markSelectArr.push(item);
            }
        },
        // 换一批标题
        titleChange(){
            if(this.markSelectIds.length >= 2) {
                this.$modal.confirm({
                    content: '您已经选中2篇稿件了，请点击开始试稿',
                })
                return;
            }
            if(this.markPage < parseInt(this.markList.length / 10)){
                this.markPage += 1;
            }else{
                this.markPage = 1;
            }
            this.updateMarkList();
        },
        // 过滤的列表数据
        updateMarkList() {
            let filterArr = this.markList;
            let index = (this.markPage - 1) * 10;
            let sliceArr = filterArr.slice(index, index + 10);
            let inSliceArr = false;
            for(let i = 0, max = sliceArr.length; i < max; i++) {
                if(this.markSelectIds.indexOf(sliceArr[i].id) >= 0) {
                    inSliceArr = true;
                }
            }
            console.log(inSliceArr);
            // 当前批标题没有已选中的标题
            if(!inSliceArr) {
                this.filterMarkList = this.markSelectArr.concat(sliceArr.slice(0, 10 - (this.markSelectArr.length)))
            }else{
                this.filterMarkList = sliceArr;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.guide-wrap{
    text-align: center;
    font-size: 16px;
    padding-bottom: 80px;
    min-height: 400px;
    position: relative;
    b{
        color: #ff4d4f
    }
    &__title{
        font-size: 24px;
        margin-bottom: 20px;
    }
    &__bottom{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        /deep/ .ant-btn{
            margin: 0 10px;
        }
    }
}
// 文章类别
.category-loading{
    padding: 20px 0;
}
// 接稿类目
.category-list{
    &__item{
        padding: 8px 24px;
        border-radius: 4px;
        display: inline-block;
        border: 1px solid #d8d8d8;
        margin: 0 12px 12px 0;
        position: relative;
        user-select:   none;
        &--active{
            color: @color-red
        }
        &:hover{
            cursor: pointer;
        }
    }
    &__icon{
        position: absolute;
        font-size: 24px;
        bottom: -2px;
        right: -6px;
        font-weight: 700;
        color: @color-red;
    }
}
// 文章列表
.mark-loading{
    padding: 30px 0;
}
.mark-list{
    /deep/ .ant-btn{
        display: block;
        width: 100%;
        text-align: left;
        margin: 0 0 10px;
    }
}
</style>
